$iconcolor: #f5a623;
$bgcolor: #01aff4;
$font: #ffffff;

.nav-one {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 10px 25px;
  border-bottom: 1px solid #a59e9e;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 2px;
   
  @media only screen and (max-width: 600px) {
   display: none;
  }

  li {
    cursor: pointer;
    padding: 0 10px;
    border-right: 1px solid #a59e9e;
  }

  li:first-of-type {
    color: $iconcolor;
  }

  li:last-of-type {
    border-right: none;
  }

  li:nth-child(9) {
    margin-left: 30px;
  }

  span {
    color: $iconcolor;
  }

  .nav-icons {
    margin-right: auto;
  }
}

.nav-two {
  display: flex;
  //justify-content: space-around;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 80px;
  margin-bottom: 0;

  .nav-search {
    flex-basis: 500px;
    margin-left: auto;
    margin-right: auto;

    .input-search {
      width: 90%;
      border-color: $iconcolor;
      border-radius: 5px;
    }
  }

  .info {
    display: flex;

    .fa-headphones {
      font-size: 40px;
      color: $iconcolor;
      margin-right: 10px;
    }

    .info-contact {
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }
  }
}

.nav-three {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 80px;
  background-color: $bgcolor;
  color: $font;
  margin-bottom: 0;

  li{
    padding: 0 5px;
  }

}



.main-container {
  padding: 10px 50px;


  .categories-main {
    display: flex;

    .categories {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      background-color: #01aff4;
      flex: 1 1 0;

      li: {
        padding: 10px;
      }

      li:nth-child(1) {
        flex-basis: 50%;
       // height: 300px;
        background-color: #464545;
      }

      li:nth-child(2) {
        flex-basis: 50%;
       // height: 300px;
        background-color: #464545;
      }

      li:not(:nth-child(1), :nth-child(2)) {
        flex-basis: 100%;
        background-color: #464545;
        height: 200px;
      }

    }

    .categories-large {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      flex: 1 1 0;

      li {
        flex-basis: 400;
        background-color: #a59e9e;
       // height: 500px;
      }
    }
  }

  .topseller-cont{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
    .arrow-icon{
      border: 1px solid #000000;
      padding: 7px 10px;
      border-radius: 25px;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .topseller-items{

    .list-items{
      display: flex;
      list-style: none;
      margin: 5px;
      text-align: center;
      flex: 1 1 0;

      .topseller-item-list{
        border: 1px solid #01aff4;
        padding: 30px 10px;

        .item-title{
          font-size: 18px;
          font-weight: 600;
        }
        .item-desc{
          font-size: 14px;
          font-weight: 100;
          color: #6d6b6b;
        }
        .item-price{
          font-size: 16px;
          font-weight: 600;
          margin-top: 5px;
          margin-bottom: 5px;
        }
       

        .item-info{
          display: flex;
          justify-content: center;

          .item-cart{
            color: $bgcolor;
            font-size: 34px;
          }

          .item-rate{
            display: flex;
            justify-content: space-evenly;
          }

        }

        .item-option{
          display: flex;
          justify-content: space-around;
          .item-option-wish{
            font-size: 12px;
          }

          .item-option-eye{
            font-size: 12px;
          }

        }

        p{
          margin-bottom: 0;
        }





      }

    }
  }

  .ads-image-cont{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.034);
    z-index: 100;
  }

  .ads-image-2{
    opacity: 0.7;
    z-index: 100;
    cursor: pointer;
  }

  .image-text{
    position: absolute;
    top: 100px;
    left: 50px;
    z-index: 100000000;
    font-size: 50px;
    color: #000000;
  }

}
